import React, { Component } from 'react'
import { AppContext } from '../lib/AppContext'
import { observer, inject } from 'mobx-react'
import ActiveShapePieChart from '../components/ActiveShapePieChart'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { utils } from 'ethers'
import Loading from 'react-loading'
import Countup from 'react-countup'
import './Uniswap.scss'
import UniswapV1Pool from '../lib/UniswapV1Pool'
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'

interface Props {
  context?: AppContext
}

interface State {
  duration: '24h' | 'all'
  activePool?: UniswapV1Pool
}

@inject('context')
@observer
class Page extends Component<Props, State> {
  state: State = { duration: '24h' }

  private format = (value: utils.BigNumber, decimals: number, fixed = 2) => utils.commify(Number.parseFloat(utils.formatUnits(value, decimals)).toFixed(fixed))

  async componentDidMount() {
    await this.props.context?.enableWeb3()
    await this.props.context?.initUniswap(this.props.context.account)

    const pool = this.props.context!.userPools[0]
    if (pool) {
      this.onMouseEnter(pool)
    } else {
      this.setState({ activePool: this.props.context!.pools.find(p => p.symbol === 'DAI') })
    }

    this.props.context?.on('accountsChanged', this.onAccountsChanged)
  }

  componentWillUnmount() {
    this.props.context?.removeListener('accountsChanged', this.onAccountsChanged)
  }

  onAccountsChanged = () => {
    this.props.context?.initUniswap(this.props.context.account)
  }

  onMouseEnter = (pool: UniswapV1Pool) => {
    this.setState({ activePool: pool })

    if (!pool.poolSharePercentage.gt(0)) return
    if (pool.historyVolumes) return

    pool.refresh7DayVolumes()
  }

  placeholder = () => <Skeleton width={25} circle />

  render() {
    const profit = (this.state.duration === '24h' ? this.props.context!.total24HFees : this.props.context!.totalFees) ?? 0
    const { activePool } = this.state

    return (
      <div className="uniswap page">
        <div className="container">
          <div className="title">Uniswap Portfolio</div>

          <div className="overview">
            <div className="profits">
              <div className="times">
                <div className={`${this.state.duration === '24h' ? 'selected' : ''}`} onClick={_ => this.setState({ duration: '24h' })}>
                  Today(Est.)
                </div>
                <div className={`${this.state.duration === 'all' ? 'selected' : ''}`} onClick={_ => this.setState({ duration: 'all' })}>
                  ALL
                </div>
              </div>
              <div className="profit-value">
                <span className="amount">
                  <Countup start={profit * 0.9} end={profit} decimals={2} />
                </span>
                <span className="unit">DAI</span>
              </div>
              <div className="pools">
                {this.props.context?.pools.map(p => {
                  return (
                    <div className={`pair ${p.originEthShare?.eq(0) ? 'hide' : ''}`} key={p.tokenAddress}>
                      <img src={require(`../assets/crypto/${p.symbol.toLowerCase()}.svg`)} alt={p.symbol} /> {`${this.format((this.state.duration === '24h' ? p.token24HProfit : p.totalProfit) ?? 0, p.decimals)} ${p.symbol}`}
                    </div>
                  )
                })}
              </div>
            </div>

            {activePool ? <div className="update-time">{`Updated: ${activePool?.latestRefreshingTime.toLocaleTimeString()}`}</div> : undefined}

            <div className="charts">
              {this.props.context!.userPools.length > 0 ? (
                <ActiveShapePieChart
                  data={
                    this.props.context!.userPools.length > 0
                      ? this.props.context?.userPools.map(p => {
                          return { name: p.symbol, value: (this.state.duration === '24h' ? p.token24ProfitDai : p.totalProfitDai) ?? 0, color: p.color }
                        })
                      : undefined
                  }
                  width={520}
                  height={300}
                  cx={250}
                  cy={125}
                  innerRadius={73}
                  outerRadius={85}
                  stroke="#333"
                  onDataActived={e => this.onMouseEnter(this.props.context!.userPools.find(p => p.symbol === e.name)!)}
                />
              ) : (
                <div className="loading-container">
                  <Loading width={24} height={24} type="spinningBubbles" className="loader" />
                </div>
              )}
            </div>
          </div>

          <div className="pools">
            <SkeletonTheme color="#cccccca0" highlightColor="#8884d8">
              <table>
                <thead>
                  <tr>
                    <th className="market">Market</th>
                    <th>Volume(TDY.)</th>
                    <th>Share</th>
                    <th>Est. Fees(TDY.)</th>
                    <th>Total Profits</th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.context?.pools.map(i => {
                    return (
                      <tr key={i.tokenAddress} onMouseEnter={_ => this.onMouseEnter(i)}>
                        <td className="market">
                          <div className="title">
                            <img src={require(`../assets/crypto/${i.symbol.toLowerCase()}.svg`)} alt="" /> {i.symbol}/ETH
                          </div>
                        </td>

                        <td>{i.token24Volume.gte(0) ? `${this.format(i.token24Volume, i.decimals)} ${i.symbol}` : this.placeholder()}</td>
                        <td>{i.poolSharePercentage.gt(0) ? `${this.format(i.poolSharePercentage.mul(100), 18, 3)}%` : 'N/A'}</td>
                        <td className={`${i.token24HProfit?.gt(0) ? 'up' : 'down'}`}>{i.token24HProfit ? `${this.format(i.token24HProfit, i.decimals, 4)} ${i.symbol}` : this.placeholder()}</td>
                        <td className={`${i.totalProfit?.gt(0) ? 'up' : 'down'}`}>{i.totalProfit ? `${this.format(i.totalProfit, i.decimals, 4)} ${i.symbol}` : this.placeholder()}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </SkeletonTheme>
          </div>

          {activePool ? (
            <div className="details-container">
              <div className="pool-details">
                <div className="title">{activePool?.symbol} Pool</div>
                <div className="price">
                  <span>Price: </span>
                  {`${activePool?.price.toFixed(2)} DAI`}
                </div>

                <div>
                  <span>Exchange Rate:</span>
                  {`${this.format(activePool.exchangeRate, activePool.decimals)} ${activePool.symbol}/ETH`}
                </div>

                <div>
                  <span>Liquidity:</span>
                  {`${this.format(activePool.exchangeTokenBalance, activePool.decimals)} ${activePool.symbol}`}/{`${this.format(activePool.exchangeEthBalance, 18)} ETH`}
                </div>
                <div className="supply">
                  <span>Supply: </span>
                  {`${this.format(activePool.originTokenShare, activePool.decimals ?? 1)} ${activePool.symbol}`}/{`${this.format(activePool.originEthShare, 18)} ETH`}
                </div>

                <div>
                  <span>Current: </span>

                  <span>
                    <span>{`${this.format(activePool.tokenShare, activePool.decimals ?? 1)}`}</span>
                    <span className={`${activePool.tokenShare.gte(activePool.originTokenShare) ? 'up' : 'down'}`}>{`(${this.format(activePool.tokenShare.sub(activePool.originTokenShare), activePool.decimals)})`}</span>
                    <span>{` ${activePool.symbol}`}</span>
                    <span>/</span>
                    <span>{`${this.format(activePool.ethShare, 18)}`}</span>
                    <span className={`${activePool.ethShare.gte(activePool.originEthShare) ? 'up' : 'down'}`}>{`(${this.format(activePool.ethShare.sub(activePool.originEthShare), 18)})`}</span>
                    <span>{` ETH`}</span>
                  </span>
                </div>

                <div>
                  <span>Impermanent Loss:</span>
                  <span className={`${activePool.impermanentLoss > 0.000001 ? 'up' : 'down'}`}>{`${(activePool.impermanentLoss * 100).toFixed(4)}%`}</span>
                </div>

                <div>
                  <span>Today Fees:</span>
                  <span className="up">{`${this.format(activePool.token24HProfit, activePool.decimals, 4)} ${activePool.symbol}`}</span>
                </div>

                <div>
                  <span>Total Profits:</span>
                  <span className={`${activePool.totalProfit.gte(0) ? 'up' : 'down'}`}>{`${this.format(activePool.totalProfit, activePool.decimals, 4)} ${activePool.symbol}`}</span>
                </div>

                {activePool.historyVolumes ? (
                  <div className="volumes-chart">
                    <div>7-Day Fees</div>
                    <BarChart width={450} height={170} data={activePool.historyVolumes} margin={{ right: 20, top: 12 }}>
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip cursor={false} formatter={i => <span>{`${(i as number).toFixed(4)} ${activePool.symbol}`}</span>} contentStyle={{ border: 'none', background: '#2e2f4f90', borderRadius: 10, color: 'white' }} />
                      <Bar dataKey="value" fill="#8884d8" legendType="none" />
                    </BarChart>
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>{activePool.poolSharePercentage.gt(0) ? <Loading width={24} height={24} type="spinningBubbles" className="loader" /> : undefined}</div>
                )}
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>
      </div>
    )
  }
}

export default Page
