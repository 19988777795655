import { observable } from 'mobx'
import Web3 from 'web3'
import { EXCHANGE_ABI, ERC20_ABI } from '../abi'
import { Contract } from 'web3-eth-contract'
import { bigNumberify, BigNumber, BigNumberish } from 'ethers/utils/bignumber'
import { ethers, utils } from 'ethers'

const factor = bigNumberify(10).pow(18)

export default class UniswapV1Pool {
  name!: string
  symbol!: string
  exchangeAddress!: string
  tokenAddress!: string
  accountAddress!: string
  decimals!: number
  tokenBase: BigNumber
  color?: string

  @observable price = 0
  @observable impermanentLoss = 0

  @observable ethShare = bigNumberify(0)
  @observable tokenShare = bigNumberify(0)
  @observable originEthShare = bigNumberify(0)
  @observable originTokenShare = bigNumberify(0)
  @observable exchangeEthBalance = bigNumberify(0)
  @observable exchangeTokenBalance = bigNumberify(0)
  @observable exchangeRate = bigNumberify(0)
  @observable eth24Volume = bigNumberify(0)
  @observable token24Volume = bigNumberify(-1)
  @observable ethProfit = bigNumberify(0)
  @observable tokenProfit = bigNumberify(0)
  @observable totalProfit = bigNumberify(0)
  @observable totalProfitDai = 0
  @observable eth24HProfit = bigNumberify(0)
  @observable token24HProfit = bigNumberify(0)
  @observable token24ProfitDai = 0
  @observable poolSharePercentage = bigNumberify(0)
  @observable latestRefreshingTime = new Date(0)
  @observable historyVolumes?: { value: number; date: string }[]

  private web3: Web3
  private exchangeContract: Contract
  private tokenContract: Contract
  private lastAddLiquidityHeight = 0
  private gm = 0 // Geometric mean
  private busy = false

  constructor(opts: { name: string; symbol: string; decimals: number; tokenAddress: string; exchangeAddress: string; accountAddress: string }, web3: Web3) {
    Object.getOwnPropertyNames(opts).forEach((v) => (this[v] = opts[v])) // copy all properties
    this.web3 = web3
    this.exchangeContract = new web3.eth.Contract(EXCHANGE_ABI, this.exchangeAddress)
    this.tokenContract = new web3.eth.Contract(ERC20_ABI, this.tokenAddress)
    this.tokenBase = bigNumberify(10).pow(opts.decimals)
  }

  async init() {
    await this.initAccountHistory()
    await this.refresh()
  }

  private async initAccountHistory() {
    const pastAdded = await this.exchangeContract.getPastEvents('AddLiquidity', {
      filter: { provider: this.accountAddress },
      fromBlock: 0,
      toBlock: 'latest',
    })

    const pastRemoved = await this.exchangeContract.getPastEvents('RemoveLiquidity', {
      filter: { provider: this.accountAddress },
      fromBlock: 0,
      toBlock: 'latest',
    })

    const sortedEvents = pastAdded.concat(pastRemoved).sort((e1, e2) => e1.blockNumber - e2.blockNumber)

    let tokenBalance = bigNumberify(0)
    let ethBalance = bigNumberify(0)

    for (let ev of sortedEvents) {
      let token = ev.returnValues['token_amount'] as string
      let eth = ev.returnValues['eth_amount'] as string

      if (tokenBalance.eq(0) || ethBalance.eq(0)) {
        this.lastAddLiquidityHeight = ev.blockNumber
      }

      if (ev.event === 'AddLiquidity') {
        tokenBalance = tokenBalance.add(token)
        ethBalance = ethBalance.add(eth)

        // let tokenAmount = Number.parseFloat(utils.formatUnits(token, this.decimals))
        // let ethAmount = Number.parseFloat(utils.formatEther(eth))
        // let rate = tokenAmount / ethAmount
        // this.gm = Math.sqrt(rate * (this.gm || rate))
      } else {
        tokenBalance = tokenBalance.sub(token)
        ethBalance = ethBalance.sub(eth)
      }

      if (tokenBalance.lte(0) || ethBalance.lte(0)) {
        tokenBalance = bigNumberify(0)
        ethBalance = bigNumberify(0)
        // this.gm = 0
      }
    }

    this.originEthShare = ethBalance
    this.originTokenShare = tokenBalance
    this.gm = Number.parseFloat(utils.formatUnits(tokenBalance, this.decimals)) / Number.parseFloat(utils.formatEther(ethBalance))
  }

  async refresh() {
    if ((new Date() as any) - (this.latestRefreshingTime as any) < 100) return

    await this.refreshPoolInfo()
    await this.refreshAccount()
    await this.refresh24HTxs()

    this.latestRefreshingTime = new Date()

    if (this.poolSharePercentage.eq(0)) return

    const currentRate = Number.parseFloat(utils.formatUnits(this.exchangeRate, this.decimals))
    this.impermanentLoss = (2 * Math.sqrt(this.gm / currentRate)) / (1 + this.gm / currentRate) - 1
  }

  async refreshPoolInfo() {
    const ethBalance = bigNumberify(await this.web3.eth.getBalance(this.exchangeAddress))
    const tokenBalance = bigNumberify(await this.tokenContract.methods.balanceOf(this.exchangeAddress).call())

    this.exchangeEthBalance = ethBalance
    this.exchangeTokenBalance = tokenBalance
    this.exchangeRate = this.getExchangeRate(ethBalance, 18, tokenBalance, this.decimals)

    if (this.decimals !== 18) {
      this.exchangeRate = this.exchangeRate.mul(this.tokenBase).div(factor)
    }
  }

  private async refreshAccount() {
    const poolSupply = bigNumberify(await this.exchangeContract.methods.totalSupply().call())
    const poolTokenBalance = bigNumberify(await this.exchangeContract.methods.balanceOf(this.accountAddress).call())

    const poolSharePercentage = poolTokenBalance.mul(factor).div(poolSupply)

    const ethShare = this.exchangeEthBalance.mul(poolSharePercentage).div(factor)
    const tokenShare = this.exchangeTokenBalance.mul(poolSharePercentage).div(factor)

    this.ethShare = ethShare
    this.tokenShare = tokenShare

    if (poolSharePercentage.eq(0)) return

    this.ethProfit = ethShare.sub(this.originEthShare)
    this.tokenProfit = tokenShare.sub(this.originTokenShare)

    this.totalProfit = this.tokenProfit.add(this.ethProfit.mul(this.exchangeRate).div(factor))

    this.poolSharePercentage = poolSharePercentage
  }

  async refresh24HTxs() {
    let { ethVolumes, tokenVolumes } = await this.getHistoryVolumes(0)
    const eth24Volume = ethVolumes[0].value
    const token24Volume = tokenVolumes[0].value

    this.eth24Volume = eth24Volume
    this.token24Volume = token24Volume
    this.eth24HProfit = eth24Volume.mul(this.poolSharePercentage).div(factor).mul(3).div(1000)
    this.token24HProfit = token24Volume.mul(this.poolSharePercentage).div(factor).mul(3).div(1000)
  }

  async refresh7DayVolumes() {
    if (this.busy) return
    this.busy = true

    const volumes = await this.getHistoryVolumes(7)
    this.historyVolumes = volumes.tokenVolumes.map((v) => {
      return {
        date: v.date,
        value: Number.parseFloat(utils.formatUnits(v.value.mul(this.poolSharePercentage).div(factor).mul(3).div(1000), this.decimals)), //Number.parseFloat(utils.formatUnits(v.value, this.decimals))
      }
    })

    this.busy = false
  }

  private async getHistoryVolumes(days = 7) {
    const height = await this.web3.eth.getBlockNumber()

    let now = new Date()
    const zeroHour = new Date(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 00:00:00`)

    const seconds = ((now as any) - (zeroHour as any)) / 1000
    const todayHeight = Math.max(Number.parseInt(`${seconds / 13.2}`), 0)
    const fromHeight = Math.max(height - todayHeight, this.lastAddLiquidityHeight)
    const dayBlocks = Number.parseInt(`${86400 / 13.2}`)

    const calcTokenTxs = async (from: number, to: string | number = 'latest') =>
      (await this.exchangeContract.getPastEvents('TokenPurchase', { fromBlock: from, toBlock: to })).map((i) => {
        return {
          eth_sold: bigNumberify(i.returnValues['eth_sold']),
          tokens_bought: bigNumberify(i.returnValues['tokens_bought']),
        }
      })

    const calcEthTxs = async (from: number, to: string | number = 'latest') =>
      (await this.exchangeContract.getPastEvents('EthPurchase', { fromBlock: from, toBlock: to })).map((i) => {
        return {
          tokens_sold: bigNumberify(i.returnValues['tokens_sold']),
          eth_bought: bigNumberify(i.returnValues['eth_bought']),
        }
      })

    const calcTokenVolume = (txs: { tokens_bought: BigNumber; eth_sold: BigNumber }[]) =>
      txs.reduce(
        (prev, curr) => {
          return {
            eth_sold: prev.eth_sold.add(curr.eth_sold),
            tokens_bought: prev.tokens_bought.add(curr.tokens_bought),
          }
        },
        { eth_sold: bigNumberify(0), tokens_bought: bigNumberify(0) }
      )

    const calcEthVolume = (txs: { tokens_sold: BigNumber; eth_bought: BigNumber }[]) =>
      txs.reduce(
        (prev, curr) => {
          return {
            tokens_sold: prev.tokens_sold.add(curr.tokens_sold),
            eth_bought: prev.eth_bought.add(curr.eth_bought),
          }
        },
        { tokens_sold: bigNumberify(0), eth_bought: bigNumberify(0) }
      )

    const tokenVolumes: { value: BigNumber; date: string }[] = []
    const ethVolumes: { value: BigNumber; date: string }[] = []

    let to = days === 0 ? height : fromHeight
    let from = days === 0 ? fromHeight : to - dayBlocks

    do {
      const tokenDayTxs = await calcTokenTxs(from, to)
      const ethDayTxs = await calcEthTxs(from, to)

      const tokenDayVolume = calcTokenVolume(tokenDayTxs)
      const ethDayVolume = calcEthVolume(ethDayTxs)

      const tokenVolume = tokenDayVolume.tokens_bought.add(ethDayVolume.tokens_sold)
      const ethVolume = tokenDayVolume.eth_sold.add(ethDayVolume.eth_bought)

      now = new Date((now as any) - 86400 * 1000)

      tokenVolumes.unshift({ value: tokenVolume, date: `${now.getMonth() + 1}/${now.getDate()}` })
      ethVolumes.unshift({ value: ethVolume, date: `${now.getMonth() + 1}/${now.getDate()}` })

      to = from
      from = to - dayBlocks
      days--
    } while (days > 0)

    return { tokenVolumes, ethVolumes }
  }

  calcDaiRate(ethPrice: number) {
    this.price = ethPrice / Number.parseFloat(utils.formatUnits(this.exchangeRate, this.decimals))
    this.totalProfitDai = Number.parseFloat(utils.formatUnits(this.totalProfit, this.decimals)) * this.price
    this.token24ProfitDai = Number.parseFloat(utils.formatUnits(this.token24HProfit, this.decimals)) * this.price
  }

  getExchangeRate(inputValue: BigNumber, inputDecimals: BigNumberish, outputValue: BigNumber, outputDecimals: BigNumberish, invert = false) {
    const factor = ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(18))

    if (invert) {
      return inputValue
        .mul(factor)
        .mul(ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(outputDecimals)))
        .div(ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(inputDecimals)))
        .div(outputValue)
    } else {
      return outputValue
        .mul(factor)
        .mul(ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(inputDecimals)))
        .div(ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(outputDecimals)))
        .div(inputValue)
    }
  }
}
